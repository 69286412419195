/**
 * This component will be used on category pages for both sub-category and brand navigation.
 *
 * @module views/components/CategoryNav
 * @memberof -Common
 */
import './CategoryNav.scss';

import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import CategoryCard from '@ulta/components/CategoryCard/CategoryCard';

import { constants } from '@ulta/utils/constants/constants';

/**
 * Represents a CategoryNav component
 *
 * @method
 * @param {CategoryNavProps} props - React properties passed from composition
 * @returns CategoryNav
 */
export const CategoryNav = ( props ) => {
  const categoryRef = useRef( null );
  const [currentCategoryRef, setCurrentCategoryRef] = useState( '' );
  const { title, categories, shopAllLabel, shopAllAction, rootMargin, root, threshold } = props;
  const isIntersecting = useIntersectionObserver( categoryRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );


  useEffect( () => {
    // set categoryRef
    if( categoryRef.current ){
      setCurrentCategoryRef( categoryRef.current );
    }
  }, [] );

  return (
    <div className='CategoryNav'
      ref={ categoryRef }
    >
      <div className='CategoryNav__wrapper'>
        <div className='CategoryNav__content'>
          <div className='CategoryNav__header'>
            <Text
              textStyle='title-6'
              htmlTag='h2'
            >
              { title }
            </Text>
          </div>
          <ul className='CategoryNav__list'>
            { shopAllAction && shopAllLabel &&
            <li className='CategoryNav__list--item'>
              <CategoryCard
                navType={ 'viewAll' }
                shopAllLabel={ shopAllAction?.label }
                allProductsLabel={ shopAllLabel }
                action={ shopAllAction }
              />
            </li>
            }
            { categories?.length > 0 && categories.map( ( category, index ) => (
              <li className='CategoryNav__list--item'
                key={ index }
              >
                <CategoryCard
                  navType={ 'category' }
                  imageMetaData={ {
                    width: '300',
                    height: '300'
                  } }
                  action={ { ...category?.action } }
                  image={ category?.image?.imageUrl }
                  label={ category?.action?.label }
                  key={ index }
                />
              </li>
            ) )
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef CategoryNavProps
 * @type {object}
 * @property {string} title - Sets the category title text.
 * @property {string} shopAllLabel - Sets the all shop label.
 * @property {object} shopAllAction - Sets the shop all action.
 * @property {array.<{image: object, action: object, description: string}>} categories - List of categories to render and display.
 */
export const propTypes = {
  /** Sets the category title text. */
  title: PropTypes.string,
  /** Sets the all shop label. */
  shopAllLabel: PropTypes.string,
  /** Sets the shop all action. */
  shopAllAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  /** List of categories to render and display. */
  categories: PropTypes.arrayOf(
    PropTypes.shape( {
      image: PropTypes.object,
      action: PropTypes.object
    } )
  )
};
export const defaultProps = {

  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

CategoryNav.propTypes = propTypes;
CategoryNav.defaultProps = defaultProps;
export default CategoryNav;
